import React from 'react';
import { useLipscoreProductData } from '../ProductPage/Lipscore/useLipscoreProductData';
import { LipscoreProductRating } from '../ProductPage/Lipscore/LipscoreProductRating';
import styled from 'react-emotion';
import ProductReviews from '../ProductPage/components/ProductReviews/ProductReviews';
import theme from '@jetshop/ui/utils/theme';

const CategoryPageReviewsWrapper = styled('div')`
  background-color: #f7f7f7;
  text-align: center;
  margin-top: 40px;
  padding-top: 30px;
  padding-bottom: 40px;
  h2 {
    font-family: ${theme('fonts.body')};
  }

  .star-rating {
    margin: 0;
  }

  .rating-text {
    span {
      text-decoration: none !important;
    }
  }
`;

export const CategoryPageReviews = () => {
  const { lipscoreData } = useLipscoreProductData({
    id: 'service_review'
  });

  return (
    <CategoryPageReviewsWrapper>
      {lipscoreData && (
        <>
          <h2>Recensioner</h2>
          <LipscoreProductRating
            rating={lipscoreData?.rating}
            identifier={lipscoreData?.internal_id}
            reviewCount={lipscoreData?.review_count}
            text={{
              rating: lipscoreData?.rating,
              review_count: lipscoreData?.review_count,
              votes: lipscoreData?.votes
            }}
          />
          <ProductReviews data={lipscoreData} />
        </>
      )}
    </CategoryPageReviewsWrapper>
  );
};
